@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  html, body {
    margin: 0;
    height: 100%;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  }

  :focus {
    outline: none !important;
  }
}
